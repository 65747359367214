<template>
    <div>
        <hero :src="athlete.acf.athlete_image"/>
        <contents :value="athlete" />
    </div>
</template>

<script>

    export default {
        components: {
            Hero: () => import ('@/components/athlete/Hero'),
            Contents: () => import('@/components/athlete/Contents')
        },
        data: () => ({
            athlete: null
        }),
        mounted() {
            this.$store.dispatch('app/setLink', -1)
            fetch(`https://ktt01.de/wordpress/?rest_route=/athlete/post/${this.$route.params.id}`)
                .then((r) => r.json())
                .then((res) => this.athlete = res[0])
        }
    }
</script>
